import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import mainBackground from "../images/background-front.jpg";
import sigle from "../images/sigle.png";
import variable from "./variable";
import WrapperImage from "./wrapperImage";
import Newsletter from "./newsletter";

class ImageFront extends React.Component {
    render() {
        const { surtitle, title, span, subtext, background, opacity, opacityLevel } = this.props;
        return (
            <Front>
                <Background>
                    <WrapperImage>
                        <ImgContainer background={background} opacity={opacity} opacityLevel={opacityLevel}>
                            <Description>
                                <h4>{surtitle}</h4>
                                <h2>
                                    {title} <span>{span}</span>
                                </h2>
                                <p>{subtext}</p>
                            </Description>
                            <img className="sigle" src={sigle} alt="sigle" />
                        </ImgContainer>
                    </WrapperImage>
                </Background>
                <WrapperImage>
                    <Newsletter />
                </WrapperImage>
            </Front>
        );
    }
}

const ImgContainer = styled.div`
    background-image: url(${props => props.background});
    object-fit: cover;
    height:550px
    padding-top:190px
    padding: 0 20px 0 20px;
    height: 500px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    position: relative;
    padding-top: 175px;
    z-index: -1;
    &:before {
        content: ${props => props.opacity};
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        height: 100%;
        width: 100%;
        z-index: -1;
        background-color: #fff;
        opacity: ${props => props.opacityLevel};
    }
    .sigle {
        height: 24px;
        position: absolute;
        top: 50%;
        margin-top: -12px;
        margin-left: -40px;
    }

    @media screen and (max-width: 992px) {
        .sigle {
            display: none;
        }
    }
    @media screen and (max-width: 600px) {
        height: 390px;
        padding-top: 85px;
    }
    @media screen and (min-width: 1441px) {
        height:550px;
        padding-top:200px;

    }
`;
const Background = styled.div`
    background-color: ${variable.lightGrey};
    position: relative;
    z-index: -999;
`;
const Description = styled.div`
    padding-left: 80px;

    color: ${variable.bodyColor};
    h2 {
        margin-top: 0;
        margin-bottom: ${variable.marginbase};
        font-weight: normal;
        color: ${variable.primaryColor};
        span {
            font-weight: bold;
        }
    }
    h4 {
        font-weight: normal;
        margin-bottom: 10px;
    }
    p {
        margin-top: ${variable.marginbase};
        max-width: 600px;
        margin: 0;
        color: ${variable.darkGrey};
        font-weight: 500;
    }
    @media screen and (max-width: 992px) {
        padding: 0 20px 0 20px;
    }
`;

const Front = styled.div`
    padding-bottom: 10px;
`;

ImageFront.defaultProps = {
    surtitle: "",
    title: "",
    span: "",
    subtext: "",
    background: mainBackground,
    opacity: "''",
    opacityLevel: 0.7,
};

ImageFront.propTypes = {
    surtitle: PropTypes.string,
    title: PropTypes.string,
    span: PropTypes.string,
    subtext: PropTypes.string,
    background: PropTypes.node,
    opacity: PropTypes.oneOf(["''", "none"]),
    opacityLevel: PropTypes.number,
};

export default ImageFront;
