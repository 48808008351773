import React from "react";
import styled from "styled-components";
import variable from "./variable";
import quatre from "../images/quatre.png";
import douze from "../images/douze.png";
import { valeur, principe } from "../utils/agiliteContent.json";

const advice = [
    {
        titre: "Valeurs",
        data: valeur,
        img: quatre,
    },
    {
        titre: "Principes",
        data: principe,
        img: douze,
        right: true,
    },
];

const contentAgilite = () => (
    <React.Fragment>
        {advice.map(adviceItem => {
            const { titre, data, img, right } = adviceItem;
            return (
                <Container key={titre}>
                    <div className="title" style={right ? { justifyContent: "flex-end" } : null}>
                        <img src={img} className="nombre" alt="nombre" />
                        <h2 className="title-before mb0">{titre}</h2>
                    </div>
                    <div className="flex-container">
                        {data.map(dataItem => {
                            return (
                                <AdviceUnit key={dataItem.number}>
                                    <h3 className="mb0 mt0">{dataItem.number}</h3>
                                    <p className="mt0 mb0">
                                        <span>{dataItem.textspan} </span>
                                        {dataItem.text}
                                    </p>
                                </AdviceUnit>
                            );
                        })}
                    </div>
                </Container>
            );
        })}
    </React.Fragment>
);

const Container = styled.div`
    background-color: ${variable.lightGrey};
    margin: 80px 0 40px 0;
    padding: 0 40px 20px 40px;
    .nombre {
        height: 90px;
    }
    .title {
        position: relative;
        display: flex;
        padding: 0 20px 0 20px;
        img {
            margin-top: -45px;
        }
    }
    .flex-container {
        display: flex;
        flex-wrap: wrap;
    }

    @media screen and (max-width: 600px) {
    padding: 0 20px 20px 20px;
    .title {
        position: relative;
        display: flex;
        padding: 0 20px 0 20px;
        img {
            margin-top: -30px;
        }
    .nombre {
            height: 60px;
        }
    }
`;

const AdviceUnit = styled.div`
    width: 25%;
    padding: 20px;
    h3 {
      color: ${variable.lightPrimary};
      font-size: 30px !important;
      line-height: 0.8em;
    }
    p {
      line-height: 1.2em;
    }
  }
  @media screen and (max-width: 992px) {
      width: 50%;
      padding: 10px;
  }
  @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
  `;
export default contentAgilite;
