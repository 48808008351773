import React from "react";
import ImageFront from "../components/ImageFront";
import Wrapper from "../components/wrapper";
import ValeursPrincipes from "../components/agiliteValeursPrincipes";
import { frontImageText, title } from "../utils/agiliteContent.json";
import image from "../images/statue1400.jpg";
import Layout from "../components/layout";

const Agile = () => (
    <Layout>
        <ImageFront
            surtitle={frontImageText.surtitle}
            title={frontImageText.title}
            span={frontImageText.span}
            subtext={frontImageText.subtext}
            background={image}
            opacityLevel={0.8}
        />
        <Wrapper>
            <h1>{title}</h1>
            <ValeursPrincipes />
        </Wrapper>
    </Layout>
);

export default Agile;
