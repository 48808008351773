import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const wrapperImage = ({ children }) => <Container>{children}</Container>;

const Container = styled.div`
    margin: 0 auto;
    max-width: 1350px;
    padding: 0 40px 0 40px;
    @media screen and (max-width: 1450px) {
        max-width: 1200px;
    }
    @media screen and (max-width: 1024px) {
        max-width: 950px;
        padding: 0 20px 0 20px;
    }
    @media screen and (max-width: 768px) {
        padding: 0;
    }
`;

wrapperImage.propTypes = {
    children: PropTypes.node.isRequired
};

export default wrapperImage;
