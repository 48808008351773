import React from "react";
import styled from "styled-components";
import addToMailchimp from "gatsby-plugin-mailchimp";
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/core";
import variable from "./variable";

class newsletter extends React.Component {
    state = {
        email: null,
        messageError: null,
        success: false,
        loading: false,
    };

    override = css`
        display: block;
        margin: 0 auto;
    `;

    handleChange = e => {
        this.setState({
            [`${e.target.name}`]: e.target.value,
        });
        if (e.target.value === "") {
            this.setState({ messageError: null });
        }
    };

    handleSubmit = e => {
        e.preventDefault();
        const { email } = this.state;
        this.setState({ loading: true });

        addToMailchimp(email, this.state)
            .then(({ msg, result }) => {
                if (msg.includes("subscribed")) {
                    this.setState({ messageError: "Oops ! Vous êtes déjà inscrit à notre Newsletter" });
                }
                if (msg.includes("invalid") || msg.includes("not valid")) {
                    this.setState({ messageError: "Email invalide" });
                }
                if (msg.includes("requests")) {
                    this.setState({ messageError: "Il y a eu trop de demandes d'inscriptions récentes" });
                }
                if (result !== "success") {
                    const { messageError } = this.state;
                    throw messageError;
                }
                this.setState({
                    success: true,
                    messageError: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({ messageError: err, success: false, loading: false });
            });
    };

    retour = () => {
        this.setState({ success: false });
    };

    render() {
        const { messageError, success, loading } = this.state;
        return (
            <Newsletter style={{ marginTop: messageError ? "-34px" : "-27px" }}>
                <form onSubmit={this.handleSubmit} className="newsletter-content">
                    {success ? (
                        <div style={{ color: variable.primaryColor }}>
                            <span>Génial !</span> Vous êtes désormais inscrit à notre Newsletter
                            <a
                                onClick={this.retour}
                                style={{ marginLeft: "25px", cursor: "pointer", color: variable.darkGrey }}
                            >
                                Retour
                            </a>
                        </div>
                    ) : (
                        <React.Fragment>
                            <p>
                                S'inscrire à la <span>Newsletter</span>
                            </p>
                            <div style={{ flex: "1" }}>
                                <div className="input">
                                    <input type="email" name="email" onChange={this.handleChange} />
                                    <button type="submit">
                                        {loading ? (
                                            <div className="sweet-loading">
                                                <ClipLoader
                                                    css={this.override}
                                                    sizeUnit="px"
                                                    size={20}
                                                    color={variable.lightSecondary}
                                                    loading={loading}
                                                />
                                            </div>
                                        ) : (
                                            <React.Fragment>S'inscrire</React.Fragment>
                                        )}
                                    </button>
                                </div>
                                <div className="reponse">{messageError}</div>
                            </div>
                        </React.Fragment>
                    )}
                </form>
            </Newsletter>
        );
    }
}

const Newsletter = styled.div`

    min-height:54px;
  box-shadow: ${variable.boxShadow};
  border-radius: 20px;
  z-index: 4;
  width: 60%;
  min-width:650px;
  margin: 0 auto;
  margin-top: -27px;
  background-color: #fff;
  display: flex;
  align-items: center;
  @media screen and (max-width: 767px) {
    height:auto;
    width:100%;
    min-width:100%;
    margin-top: -50px;
    border-radius:20px;


    }
  .reponse
    {
    font-size:12px;
    padding-left:10px;
    color:${variable.secondGrey};
    }
    .newsletter-content:first-child{
        color:${variable.bodyColor}
        a {
            margin-left:10px;
            padding:5px;
            border 1px solid ${variable.bodyColor};
        }
    }
  .newsletter-content {
  padding: 10px 10% 10px 10%;
  display:flex;
  flex:1;
  align-items: center;
  justify-content:center;
  @media screen and (max-width: 767px) {
    flex-direction:column;
    padding:15px 20px;
    align-items:stretch;
}
    span {
      font-weight: bold;
    }

    p {
      margin: 0;
      margin-right:40px;
      color: ${variable.primaryColor};
      @media screen and (max-width: 767px) {
        margin: 0 0 10px 0
    }
    }
    .input {
      display:flex;
      flex:1;
      input {
        height: 32px;
        border-radius: 12px 0 0 12px;
        border: 1px solid ${variable.lightPrimary};
        outline: none;
        border-right: none;
        flex:1;
        padding: 0 10px 0 10px;
        color:${variable.bodyColor};
        line-height:32px;
        @media screen and (max-width: 767px) {
            height:30px;
        }
        @media screen and (max-width: 320px) {
            max-width: 180px;
        }
      }
      button {
        color: ${variable.secondaryColor};
        background: transparent;
        border-radius: 0 12px 12px 0;
        height: 32px;
        outline: none;
        border: 1px solid ${variable.secondaryColor};
        padding: 5px 20px 5px 20px;
        cursor:pointer;
        transition:${variable.transition};
        display:flex;
        align-items:center;
        @media screen and (max-width: 767px) {
            height:30px;
            padding:5 10px 5 10px;
            font-size:15px;
        }
      }
      button:hover {
        color:#fff;
        background:${variable.secondaryColor};
      }

  }
`;

export default newsletter;
